@import '../../shared/style/index.scss';

.header {
    background: $blue-gradient;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    .logo {
        padding: 7px;
        width: auto;
        height: inherit;
        object-fit: contain;
    }
}

.container {
    @include flex();
    justify-content: space-between;
    width: 100%;
    background: $blue-gradient;
    gap: $small;
    padding: 0px 35px;

    .logoLeft {
        width: auto;
        @include centerFlex();
        cursor: pointer;
        z-index: 600;

        img {
            width: auto;
            height: 80px;
            padding: 15px;
            object-fit: contain;
            z-index: 50;
        }
    }

    @include resolution(tablet) {
        justify-content: center;
        align-items: stretch;
        padding: $x-small 0;
        .logoLeft {
            display: none;
        }
    }
}
