@import '../../../shared/style/variable';
@import '../../../shared/style/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 40px;
    background: $gradient-blue-secondary;
    color: $white;
    padding: 2rem 2.5rem;
    text-align: start;
    min-height: 100vh;

    h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    ul {
        list-style: inside;
        padding-left: 1.25rem;
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 10px;

        h2 {
            font-size: 1.5rem;
            text-decoration: underline;
        }

        p {
            font-size: 1.25rem;
        }
    }

    @include resolution(tablet) {
        padding: 2rem 1rem;
    }
}
