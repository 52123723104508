@import '../../../shared/style/mixins';
@import '../../../shared/style/variable';

.page {
    @include pageInit();
    padding: 20px 40px;

    @include resolution(mobile) {
        padding: 20px;
        padding-bottom: 90px;
    }
    @include resolution(x-mobile) {
        padding: 20px 5px;
        padding-bottom: 90px;
    }

    @include columnCenterTopFlex();
    align-items: stretch;
    gap: $medium;

    .roadInformation {
        text-align: center;
        font-weight: 500;
    }

    .infoPopup {
        background: $gradient-low-opacity-left;
        padding: 0.2rem 0.9rem;
        display: flex;
        max-width: max-content;
        font-weight: 500;
        border-radius: 5px;
    }

    .btnGroupContainer {
        @include flex();
        justify-content: space-between;
        gap: $large;

        .btnGroup {
            @include flex();
            gap: $small;

            button {
                text-transform: uppercase;
                span {
                    @include flex();
                    justify-content: space-between;
                    gap: 30px;
                }
            }
        }

        @include resolution(x-desktop) {
            justify-content: center;
            .informationFilter {
                display: none;
            }
        }

        @include resolution(tablet) {
            .btnGroup {
                .indicatorFilter {
                    display: none;
                }
            }
        }
    }

    .container {
        section {
            margin: 10px auto;
            display: flex;

            > div {
                &:first-child {
                    display: grid;
                    width: 100%;

                    min-height: 200px;
                }
            }

            button {
                justify-self: flex-end;
                align-self: flex-end;
                margin: 10px;
            }
        }
    }

    .informationCard {
        @include centerFlex();

        h2 {
            @include title($color: $color-secondary, $size-mobile: 17px);
            text-align: center;
        }

        @include resolution(mobile) {
            padding: $medium;
            min-height: auto;
        }
    }

    .validation {
        background: #dddee3;
        @include columnCenterFlex();

        > i {
            font-size: 50px;
            color: $color-secondary;
        }

        > p {
            color: $color-secondary;
            font-size: $medium-font;
            text-align: center;
            margin: $medium auto;
            span {
                font-weight: bold;
            }
        }
    }

    .unLock {
        background: $gradient-low-opacity-left;
    }
}
